<template>
  <div class="box">
    <div class="boxButtom">
      <div class="wraper" id="footer">
        监管机构： 国家食品药品监管局 湖北省食品药品监管局 <a @click="imgOpen" href="#">互联网药品信息服务资格证：（鄂）-非经营性-2023-0006
        </a>
        <br>
        <a href="https://beian.miit.gov.cn" target="_blank">鄂ICP备2023000699号-1</a>
        <br>
        <a href="http://61.183.154.3/portal/portalAction!getPage.dhtml?channel_id=0&forward=wlba_search&index=wlba_pageWHADp&isSd=&node_id=&cat_id=&clear=true&qymc=%E5%81%A5%E5%8D%9A%E4%BC%9A%E5%A4%A7%E8%8D%AF%E6%88%BF&uniscid=" target="_blank">医疗器械网络销售备案：鄂汉药监械经营许:20230145号</a>
        <br>

        <span style="margin:10px 0px;display: inline-block;">
          Copyright© 2019
          <!-- <span class="vol">
            <font class="b">expowh.com</font>
          </span> -->
          武汉健博会大药房有限公司 版权所有
        </span>

        <!-- <div style="display: inline-block; vertical-align: middle;">
          <a target="_blank" href="http://www.beian.gov.cn/portal/registerSystemInfo?recordcode=42010502001214" style="display:inline-block;"><img src="@/assets/egaba.png" style="float:left;"><span style="float:left;">鄂公网安备 42010502001214号</span></a>
        </div> -->
        <br>
      </div>
    </div>
  </div>

</template>

<script setup>
import imageUrl from "/src/assets/image_yy.png"

function imgOpen () {
  const image = new Image()
  image.src = imageUrl
  image.onload = () => {
    image.style.margin = "0 auto"
    image.style.display = "block"
    const newWin = window.open("", "_blank")
    newWin.document.write(image.outerHTML)
  }
}

</script>

<style scoped lang="less">
.box {
  width: 100%;
  height: 122px;
  font-size: 12px;
  background-color: rgb(248, 248, 248);
  margin-top: 20px;
  .boxButtom {
    width: 948px;
    height: 100%;
    margin: 0 auto;
    text-align: center;
    padding-top: 30px;
    a {
      color: #685f5f;
    }
  }
}
.b {
  color: blue;
}
</style>
