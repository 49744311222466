import { createRouter, createWebHashHistory, createWebHistory } from "vue-router"

//1. 定义要使用到的路由组件  （一定要使用文件的全名，得包含文件后缀名）
import index from "@/pages/home/home.vue"
import about from "@/pages/about/about.vue"
import commodity from '@/pages/commodity/commodity.vue'
import contact from '@/pages/contact/contact.vue'
import details from '@/components/details/details.vue'


//2. 路由配置
const routes = [
  {
    path: "/",
    redirect: "/index",
  },
  { path: "/index", component: index },
  { path: "/about", component: about },
  { path: "/commodity", component: commodity },
  { path: "/contact", component: contact },
  { path: "/details", component: details },

]

// 3. 创建路由实例
const router = createRouter({
  // （1）采用hash 模式
  history: createWebHashHistory(),
  // （2）采用 history 模式
  // history: createWebHistory(),
  routes, 
})


export default router
