<template>
  <div class="app_cp">
    <div class="homebanner">
      <img src="@/assets/home/banner.png" alt="">
    </div>
    <div class="homerecommend">
      <div class="homerecommendtop">
        <h3>
          产品推荐{{str1.a}}
          <span class="heng"></span>
        </h3>
      </div>
      <div class="pl" style="margin-right: -100px;padding-left: 14px;">
        <div class="homerecommendTxtmg" v-for="(item,index) in list" :key="index" @click="shopDetails(item.id)">
          <div class="boximg">
            <img :src="item.img" alt="">
          </div>
          <div class="text">
            <h5>{{item.title}}</h5>
            <p>
              <span class="chang">厂</span>
              <span class="Pspan">{{item.manufactor}}</span>
            </p>
          </div>
        </div>
      </div>

    </div>
    <div class="homemy">
      <div class="homerecommendtop">
        <h3>
          关于我们
          <span class="heng"></span>
        </h3>
      </div>
      <div class="homemyText">
        <div class="homemyImg">
          <img src="@/assets/home/home.png" alt="">
        </div>
        <div class="homemytxt" v-for="(item,index) in text" :key="index">
          <p>
            <span class="leftL"></span>
            <span>{{item.title}}</span>
          </p>
          <p style="font-size:12px">{{item.text}}</p>
        </div>
      </div>

    </div>
  </div>
</template>

<script setup>

import { ref, reactive, toRefs, getCurrentInstance } from "vue"
import { useRouter } from 'vue-router'
// const {proxy} = getCurrentInstance()
// proxy.$message({ type: 'error', message: '登录失败' }) 


let str1 = reactive({
  list: [{
     id: "xb",
    title: "硝苯地平缓释片(Ⅱ)(伲福达) 20mg *30片",
    manufactor: "青岛黄海制药有限责任公司",
    img: require("@/assets/home/a1.png")
  },
  {
    id: "xg",
    title: "桂林西瓜霜 (三金) 3.5g",
    manufactor: "桂林三金药业股份有限公司",
    img: require("@/assets/home/a2.png")
  },
  {
    id: "tb",
    title: "头孢克肟颗粒(达力芬)(无糖) 50mg*12包 无糖型",
    manufactor: "深圳致君制药",
    img: require("@/assets/home/a3.png")
  },
  {
     id: "yb",
    title: "腰痹通胶囊(康缘) 0.42g*100粒",
    manufactor: "江苏康缘药业",
    img: require("@/assets/home/a4.png")
  },
  {
    id: "cy",
    title: "肠炎宁片 0.42g*12s*4板 薄膜衣",
    manufactor: "江西康恩贝中药有限公司",
    img: require("@/assets/home/a5.png")
  },
  {
     id: "bq",
    title: "贝前列素钠片 (凯那) 40ug*10片",
    manufactor: "北京泰德制药股份有限公司",
    img: require("@/assets/home/a6.png")
  },
  {
    id: "ys",
    title: "盐酸坦索罗辛缓释胶囊 0.2mg*7s*2板",
    manufactor: "江苏恒瑞医药股份有限公司",
    img: require("@/assets/home/a7.jpg")
  },
  {
    id: "kd",
    title: "坎地沙坦酯分散片 (奥必欣/源瑞制药) 4mg*16片",
    manufactor: "昆明源瑞制药",
    img: require("@/assets/home/a8.png")
  }],
  text: [{
    title: "药房信息",
    text: "武汉健博会大药房有限公司整合全国乃至全球大健康产业资源，服务健康中国战略。未利用好健博会资源，更好的为人民身体健康服务，于2022年09月20号正式成立，主要经营药品、食品、医疗器械及大健康相关产品。"
  },
  {
    title: "产品经营信息",
    text: "武汉健博会大药房主经营品种主要以药品、医疗器械产品为主。"
  }]
})
let { list, text } = toRefs(str1)

const router = useRouter()


function shopDetails (id) {
  router.push({
    path: `details`,
    query: { id }
  })
}

</script>

<style scoped lang="less">
.app_cp {
  background: #ffffff;
}
.homebanner {
  img {
    width: 100%;
  }
}
.homerecommend {
  width: 1024px;
  margin: 0 auto;
  margin-top: -5px;
  padding: 28px 0px;
  .homerecommendtop {
    text-align: center;
    margin-bottom: 25px;
    .heng {
      display: block;
      width: 38px;
      height: 3px;
      background-color: #116d52;
      margin: 3px auto;
    }
  }
  .homerecommendTxtmg {
    width: 156px;
    margin-right: 120px;
    display: inline-block;
    text-align: center;
    margin-top: 20px;
    .boximg {
      width: 119px;
      height: 109px;
      padding: 20px;
      border: 1px solid #d9d9d9;
    }
    img {
      width: 72px;
      height: 87px;
      transition: all 0.3s ease;
    }
    img:hover {
      transform: scale(1.1);
    }
    .text {
      text-align: left;
      h5 {
        margin: 7px 0px;
        width: 156px;
        height: 35px;
        font-size: 12px;
        -webkit-line-clamp: 2;
        word-break: break-all;
        display: -webkit-box;
        -webkit-box-orient: vertical;
        overflow: hidden;
        text-overflow: ellipsis;
      }
      p {
        margin: 0px;
        font-size: 12px;
        display: flex;
        .chang {
          width: 18px;
          height: 15px;
          text-align: center;
          background-color: #b4c8ff;
          margin-right: 5px;
        }
        .Pspan {
          width: 156px;

          -webkit-line-clamp: 1;
          word-break: break-all;
          display: -webkit-box;
          -webkit-box-orient: vertical;
          overflow: hidden;
          text-overflow: ellipsis;
        }
      }
    }
  }
  .homerecommendTxtmg:hover {
    cursor: pointer;
  }
}
.homemy {
  width: 1024px;
  margin: 0 auto;
  margin-top: -5px;
  padding: 28px 0px;
  .homerecommendtop {
    text-align: center;
    margin-bottom: 25px;
    .heng {
      display: block;
      width: 38px;
      height: 3px;
      background-color: #116d52;
      margin: 3px auto;
    }
  }

  .homemyText {
    width: 900px;
    margin-left: 50px;
    .homemyImg {
      height: 321px;
      img {
        width: 100%;
      }
    }
    .homemytxt {
      p {
        display: flex;
        margin-bottom: 10px;
      }
      .leftL {
        display: block;
        width: 3px;
        height: 16px;
        background-color: #116d52;
        margin-right: 6px;
        margin-top: 3px;
      }
    }
  }
}
</style>
