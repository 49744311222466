<template>

  <div class="app_cp">
    <div class="homebanner">
      <img src="@/assets/b2.png" alt="">
    </div>
    <div class="homemy">
      <div class="homerecommendtop">
        <h3>
          联系我们
          <span class="heng"></span>
        </h3>
      </div>
      <div class="homemyText">
        <div class="homemyImg">
          <div id="allmap"></div>
        </div>
        <div class="homemytxt">
          <p>联系方式：13659831905</p>
          <p>联系地址：武汉市汉阳区龙阳街道九州通健康城2-A-11商铺</p>
        </div>
      </div>

    </div>
  </div>

</template>

<script>
import { defineComponent, nextTick, onMounted } from "vue";
export default defineComponent({
  setup () {
    onMounted(() => {
      nextTick(() => {
        initMap();
      });
    });
    const initMap = () => {
      let Bmap = window.BMap;
      let map = new Bmap.Map("allmap");
      // 114.20721, 30.55610    721调大向右，610调大向上
      map.centerAndZoom(new Bmap.Point(114.20721, 30.55630), 18);
    };
    return {};
  },
});
</script>

<style scoped lang="less">
.app_cp {
  background: #ffffff;
}
.homebanner {
  img {
    width: 100%;
  }
}
#allmap {
  width: 100%;
  height: 400px;
}
.homemy {
  width: 1024px;
  margin: 0 auto;
  margin-top: -5px;
  padding: 28px 0px;
  .homerecommendtop {
    text-align: center;
    margin-bottom: 25px;
    margin-top: 20px;
    .heng {
      display: block;
      width: 38px;
      height: 3px;
      background-color: #116d52;
      margin: 3px auto;
    }
  }

  .homemyText {
    width: 737px;
    margin-left: 154px;

    .homemyImg {
      height: 321px;
      img {
        width: 100%;
      }
    }
    .homemytxt {
      margin-top: 150px;
      p {
        display: flex;
        margin-bottom: 10px;
        font-size: 15px;
        font-family: Lantinghei SC;
        font-weight: 600;
        color: #333333;
        justify-content: center;
      }
    }
  }
}
</style>
