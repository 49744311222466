import { createApp } from 'vue'
import ElementPlus from 'element-plus'
import 'element-plus/dist/index.css'
import App from './App.vue'
import "./index.css"
import locale from 'element-plus/lib/locale/lang/zh-cn';
import router from './router/index'
import axios from '../lib/http/axios.js';
import _api from '../lib/http/api.js';

const app = createApp(App)

app.config.warnHandler = () => null

app.config.globalProperties.Axios = axios;
app.provide('Axios',axios)

app.config.globalProperties.Api = _api;
app.provide('Api',_api)

app.use(ElementPlus,{locale})
app.use(router)
app.mount('#app')
