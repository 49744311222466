<template>
  <div class="homeTopclass">
    <div class="box">
      <div class="boximg">
        <img src="@/assets/logo.png" @click="Jumpto()" alt="">
      </div>
      <div class="boxright">
        <router-link :to="'/index'" @click="changeColor(1)">
          <p>网站首页</p>
        </router-link>
        <router-link :to="'/about'" @click="changeColor(2)">
          <p :style="colornum == 2?'color:rgb(57 108 255)':''">关于我们</p>
        </router-link>
        <router-link :to="'/commodity'" @click="changeColor(3)">
          <p :style="colornum == 3?'color:rgb(57 108 255)':''">产品信息</p>
        </router-link>
        <router-link :to="'/contact'" @click="changeColor(4)">
          <p :style="colornum == 4?'color:rgb(57 108 255)':''">联系我们</p>
        </router-link>
        <!-- <button @click="clickLook()">点击</button> -->
      </div>
    </div>
  </div>
</template>

<script setup>
import { reactive, inject, ref } from 'vue'
import { useRoute, useRouter } from 'vue-router'
const Axios = inject("Axios");
const Api = inject("Api");

const router = useRouter()

// router.push({
//   path: `/index`
// })

let colornum = window.sessionStorage.getItem("colornum");

const Jumpto = () => {
  router.push({
    path: `/index`
  })
  colornum = 1
  window.sessionStorage.setItem("colornum", 1);
}


function changeColor (index) {
  colornum = index
  window.sessionStorage.setItem("colornum", index);
  if (index == 3) {
    window.sessionStorage.setItem("drug", 1);
  }
}
// function clickLook () {
//   Axios.get(Api.ORG("orgTree")).then((res) => {
//     console.log(res);
//   })
// }
</script>

<style scoped lang="less">
.homeTopclass {
  width: 100%;
  height: 43px;
  background: #ffffff;
  box-shadow: 0px 5px 10px 0px rgba(4, 0, 0, 0.1);

  .box {
    height: 100%;
    margin: 0px 181px;
    display: flex;
    justify-content: space-between;
    .boximg img {
      height: 22px;
      margin-top: 10px;
    }
    .boximg img:hover {
      cursor: pointer;
    }
    .boxright {
      display: flex;
      font-size: 12px;
      font-family: Source Han Sans CN;
      font-weight: 400;
      color: #000;
      p {
        margin-right: 50px;
      }
      p:hover {
        cursor: pointer;
      }
    }
  }
}
</style>
