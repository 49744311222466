<template>
  <div class="app_cp">
    <div class="homebanner">
      <img src="@/assets/banner2.png" alt="">
    </div>

    <div class="homemy">
      <div class="homerecommendtop">
        <h3>
          关于我们
          <span class="heng"></span>
        </h3>
      </div>
      <div class="homemyText">
        <div class="homemyImg">
          <img src="@/assets/home/home.png" alt="">
        </div>
        <div class="homemytxt" v-for="(item,index) in str1.text" :key="index">
          <p>
            <span class="leftL"></span>
            <span>{{item.title}}</span>
          </p>
          <p style="font-size:12px">{{item.text}}</p>
        </div>
      </div>

    </div>
  </div>
</template>

<script setup>
import { ref, reactive, toRefs } from "vue"
let str1 = reactive({
  text: [{
    title: "药房信息",
    text: "武汉健博会大药房有限公司整合全国乃至全球大健康产业资源，服务健康中国战略。未利用好健博会资源，更好的为人民身体健康服务，于2022年09月20号正式成立，主要经营药品、食品、医疗器械及大健康相关产品。"
  },
  {
    title: "产品经营信息",
    text: "武汉健博会大药房主经营品种主要以药品、医疗器械产品为主。"
  }]
})
</script>

<style scoped lang="less">
.app_cp {
  background: #ffffff;
}
.homebanner {
  img {
    width: 100%;
  }
}
.homemy {
  width: 1024px;
  margin: 0 auto;
  margin-top: -5px;
  padding: 28px 0px;
  .homerecommendtop {
    text-align: center;
    margin-bottom: 25px;
    margin-top: 40px;
    .heng {
      display: block;
      width: 38px;
      height: 3px;
      background-color: #116d52;
      margin: 3px auto;
    }
  }

  .homemyText {
    width: 900px;
    margin-left: 50px;
    .homemyImg {
      height: 321px;
      img {
        width: 100%;
      }
    }
    .homemytxt {
      p {
        display: flex;
        margin-bottom: 10px;
      }
      .leftL {
        display: block;
        width: 3px;
        height: 16px;
        background-color: #116d52;
        margin-right: 6px;
        margin-top: 3px;
      }
    }
  }
}
</style>
